import React, { useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import awsconfig from "../aws-exports";
import Home from "./Home";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "../styles.css";
import { Container } from "@mui/material";
import { fetchAuthSession, signOut } from "aws-amplify/auth";

Amplify.configure(awsconfig);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminGroup = async () => {
      try {
        const session = await fetchAuthSession();
        const groups = session.tokens.idToken.payload["cognito:groups"];

        if (!groups.includes("admin")) {
          signOut();
        } else {
          console.log("Admin user");
          setLoading(false);
        }
      } catch (error) {
        signOut();
      }
    };

    checkAdminGroup();
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{ bgcolor: "grey.100", height: "auto", minHeight: "100vh", m: 0 }}
    >
      {!loading && <Home />}
    </Container>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
  loginMechanism: "email",
  socialProviders: []
});
