import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUser } from "../graphql-use/queries";
import { generateClient } from "aws-amplify/api";

const UserPage = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  const client = generateClient();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const result = await client.graphql({
          query: getUser,
          variables: { id: userId }
        });
        const fetchedUser = result.data.getUser;

        setUser(fetchedUser);
      } catch (error) {
        console.error("Error fetching user", error);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2 style={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}>
        {user.username}
      </h2>
      <p>
        <strong>ID:</strong> {user.id}
      </p>
      <p>
        <strong>Email:</strong> {user.email}
      </p>
      <p>
        <strong>Name:</strong> {user.name || "N/A"}
      </p>
      <p>
        <strong>Picture URL:</strong> {user.pictureUrl || "N/A"}
      </p>
      <p>
        <strong>Gender:</strong> {user.gender || "N/A"}
      </p>
      <p>
        <strong>Date of Birth:</strong> {user.dateOfBirth || "N/A"}
      </p>
    </div>
  );
};

export default UserPage;
