import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { createCollection } from "../graphql-use/mutations";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { useLocation } from "react-router-dom";

const NewCollectionPage = () => {
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState(1);
  const [translations, setTranslations] = useState([
    { language: "en", name: "" },
    { language: "fi", name: "" }
  ]);
  const [hidden, setHidden] = useState(true);

  const client = generateClient();
  const location = useLocation();
  const { nextOrder } = location.state || {}; // Access nextOrder from location state

  useEffect(() => {
    if (nextOrder) {
      setOrder(nextOrder); // Set order to nextOrder if it exists
    }
  }, [nextOrder]);

  // Handler for updating translations
  const handleTranslationChange = (language, name) => {
    const updatedTranslations = translations.map((translation) => {
      if (translation.language === language) {
        return { ...translation, name };
      }
      return translation;
    });
    setTranslations(updatedTranslations);
  };

  const handleSave = async () => {
    try {
      // Prepare translations for creation
      const newTranslations = translations.map((translation) => ({
        language: translation.language,
        name: translation.name
      }));

      // Send API call to create collection
      const result = await client.graphql({
        query: createCollection,
        variables: {
          input: {
            title: title,
            order: order,
            translations: newTranslations,
            hidden: hidden
          }
        }
      });

      console.log("New collection created:", result.data.createCollection);

      // Reset form fields after successful creation
      setTitle("");
      setOrder(1);
      setTranslations([
        { language: "en", name: "" },
        { language: "fi", name: "" }
      ]);
      setHidden(false);

      NotificationManager.success("Collection created successfully", "Success");
    } catch (error) {
      console.error("Error creating collection", error);
      NotificationManager.error("Error creating collection", "Error");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2 style={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}>
        New Collection
      </h2>
      <p>
        <strong>Title:</strong>{" "}
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "1em",
            marginBottom: "20px"
          }}
        />
      </p>
      {/* Inputs for translations */}
      {translations.map((translation, index) => (
        <div key={index}>
          <p>
            <strong>{translation.language.toUpperCase()}:</strong>{" "}
            <input
              type="text"
              value={translation.name}
              onChange={(e) =>
                handleTranslationChange(translation.language, e.target.value)
              }
            />
          </p>
        </div>
      ))}
      <p>
        <strong>Order:</strong>{" "}
        <input
          type="number"
          value={order}
          onChange={(e) => setOrder(parseInt(e.target.value))}
        />
      </p>
      <p>
        <strong>Hidden:</strong>{" "}
        <input
          type="checkbox"
          checked={hidden}
          disabled
          onChange={(e) => setHidden(e.target.checked)}
        />
      </p>
      <p>
        You can edit "hidden" value after creating the collection and going to
        edit
      </p>
      <button
        onClick={handleSave}
        style={{
          padding: "10px",
          fontSize: "1em",
          backgroundColor: "#ff7d69",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer"
        }}
      >
        Create Collection
      </button>

      <NotificationContainer />
    </div>
  );
};

export default NewCollectionPage;
