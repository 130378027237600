import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getProduct,
  listCategories,
  searchBrands
} from "../graphql-use/queries";
import { generateClient } from "aws-amplify/api";
import {
  createImage,
  deleteImage,
  updateProduct
} from "../graphql-use/mutations";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { getUrl, uploadData, remove } from "aws-amplify/storage";

const ProductPage = () => {
  const { productId } = useParams();
  const [categories, setCategories] = useState([]);
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [name, setName] = useState("");
  const [savedDefaultImage, setSavedDefaultImage] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const [images, setImages] = useState([]);
  const [productBrandName, setProductBrandName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [brandId, setBrandId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subcategoryId, setSubcategoryId] = useState("");
  const [subsubcategoryId, setSubsubcategoryId] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedBrands, setSearchedBrands] = useState(null);
  const [fullText, setFullText] = useState("");
  const [reviews, setReviews] = useState([]);

  let tempCategoryId = "";
  let tempSubCategoryId = "";

  const client = generateClient();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const result = await client.graphql({
          query: getProduct,
          variables: { id: productId }
        });
        const fetchedProduct = result.data.getProduct;
        console.log(fetchedProduct.brand.name);
        setBrandId(fetchedProduct.brand.id);
        setBrandName(fetchedProduct.brand.name);

        setName(fetchedProduct.name);
        setImageFileName(fetchedProduct.imageFileName);
        setSavedDefaultImage(fetchedProduct.imageFileName);
        setImages(fetchedProduct.images.items);
        setProductBrandName(fetchedProduct.brandName);
        setCategoryId(fetchedProduct.categoryId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        tempCategoryId = fetchedProduct.categoryId;
        setSubcategoryId(fetchedProduct.subcategoryID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        tempSubCategoryId = fetchedProduct.subcategoryID;
        setSubsubcategoryId(fetchedProduct.subsubcategoryID);
        setStatus(fetchedProduct.status);
        setFullText(fetchedProduct.fullText);
        setReviews(fetchedProduct.Reviews.items);

        queryCategories();
      } catch (error) {
        console.error("Error fetching product", error);
      }
    };

    fetchProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const queryCategories = async () => {
    console.log("Query list categories ->");

    try {
      const result = await client.graphql({
        query: listCategories
      });
      const tempCategories = result.data.listCategories.items;
      const language = "en";

      const processedCategories = tempCategories
        .map((category) => {
          if (category.hidden) {
            return null;
          }

          const translation = category.translations.find(
            (t) => t.language === language
          );
          const categoryName = translation ? translation.name : category.name;

          const processedSubcategories = category.Subcategories.items
            .map((subcategory) => {
              if (subcategory.hidden) {
                return null;
              }

              const subcategoryTranslation = subcategory.translations.find(
                (t) => t.language === language
              );
              const subcategoryName = subcategoryTranslation
                ? subcategoryTranslation.name
                : subcategory.name;

              const processedSubsubcategories =
                subcategory.Subsubcategories.items
                  .map((subsubcategory) => {
                    if (subsubcategory.hidden) {
                      return null;
                    }

                    const subsubcategoryTranslation =
                      subsubcategory.translations.find(
                        (t) => t.language === language
                      );
                    const subsubcategoryName = subsubcategoryTranslation
                      ? subsubcategoryTranslation.name
                      : subsubcategory.name;

                    return {
                      id: subsubcategory.id,
                      name: subsubcategoryName,
                      subcategoryID: subsubcategory.subcategoryID,
                      iconName: subsubcategory.name,
                      order: subsubcategory.order
                    };
                  })
                  .filter(Boolean);

              const sortedSubSubCategories = processedSubsubcategories.sort(
                (a, b) => a.order - b.order
              );

              return {
                id: subcategory.id,
                name: subcategoryName,
                Subsubcategories: sortedSubSubCategories,
                categoryID: subcategory.categoryID,
                iconName: subcategory.name,
                order: subcategory.order
              };
            })
            .filter(Boolean);

          const sortedSubCategories = processedSubcategories.sort(
            (a, b) => a.order - b.order
          );

          return {
            id: category.id,
            name: categoryName,
            order: category.order,
            Subcategories: sortedSubCategories
          };
        })
        .filter(Boolean);

      const sortedCategories = processedCategories.sort(
        (a, b) => a.order - b.order
      );

      setCategories(sortedCategories);
      createMainCategoryList(sortedCategories);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const createMainCategoryList = (categories) => {
    console.log("createMainCategoryList");
    const mainCategoriesTemp = [];
    categories.forEach((cat) => {
      mainCategoriesTemp.push({
        label: cat.name,
        value: cat.id
      });
    });
    setMainCategories(mainCategoriesTemp);
    createSubCategoryList(categories);
  };

  const createSubCategoryList = (categories) => {
    console.log("createSubCategoryList");
    const subCategoriesTemp = [];
    categories.forEach((cat) => {
      if (cat.id === tempCategoryId) {
        cat.Subcategories.forEach((subCat) => {
          subCategoriesTemp.push({
            label: subCat.name,
            value: subCat.id
          });
        });
      }
    });
    setSubCategories(subCategoriesTemp);
    createSubSubCategoryList(categories);
  };

  const createSubSubCategoryList = (categories) => {
    const subSubCategoriesTemp = [];
    categories.forEach((cat) => {
      if (cat.id === tempCategoryId) {
        cat.Subcategories.forEach((subCat) => {
          if (subCat.id === tempSubCategoryId) {
            subCat.Subsubcategories.forEach((subSubCat) => {
              subSubCategoriesTemp.push({
                label: subSubCat.name,
                value: subSubCat.id
              });
            });
          }
        });
      }
    });
    setSubSubCategories(subSubCategoriesTemp);
  };

  const onSetMainCategory = (mainCategory) => {
    setCategoryId(mainCategory);
    let selectedCat = categories.filter((cat) => cat.id === mainCategory);
    //console.log("selectedCat: " + JSON.stringify(selectedCat));

    if (selectedCat.length > 0) {
      let tempSubCategories = [];
      selectedCat[0].Subcategories.forEach((subCat) => {
        if (mainCategory === subCat.categoryID) {
          tempSubCategories.push({
            label: subCat.name,
            value: subCat.id
          });
        }
      });
      setSubCategories(tempSubCategories);

      setCategoryId(mainCategory);
      setSubcategoryId("");
      setSubSubCategories([]);
      setSubsubcategoryId("");
      //console.log("sub categories" + JSON.stringify(subCategories));
    }
  };

  const onSetSubCategory = (subCategory) => {
    let selectedCat = categories.filter((cat) => cat.id === categoryId);
    let selectedSubCat = selectedCat[0].Subcategories.filter(
      (cat) => cat.id === subCategory
    );

    if (selectedSubCat.length > 0) {
      let tempSubSubCategories = [];
      selectedSubCat[0].Subsubcategories.forEach((subSubCat) => {
        if (subCategory === subSubCat.subcategoryID) {
          tempSubSubCategories.push({
            label: subSubCat.name,
            value: subSubCat.id
          });
        }
      });

      setSubcategoryId(subCategory);
      setSubSubCategories(tempSubSubCategories);
      setSubsubcategoryId("");
      //console.log("sub sub categories" + JSON.stringify(subSubCategories));
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = async () => {
    console.log("Search " + searchTerm);
    const result = await client.graphql({
      query: searchBrands,
      variables: {
        filter: {
          name: {
            matchPhrasePrefix: searchTerm.trim().toLowerCase()
          }
        },
        limit: 4
      }
    });
    console.log("result " + JSON.stringify(result));
    setSearchedBrands(result.data.searchBrands.items);
  };

  // Handler for saving changes
  const handleSave = async () => {
    try {
      const input = {
        id: productId,
        name,
        imageFileName,
        brandName: productBrandName,
        brandId: brandId,
        categoryId,
        subcategoryID: subcategoryId,
        subsubcategoryID: subsubcategoryId,
        fullText: fullText,
        status
      };
      console.log("input" + JSON.stringify(input));

      const result = await client.graphql({
        query: updateProduct,
        variables: {
          input: input
        }
      });

      setSavedDefaultImage(result.data.updateProduct.imageFileName);

      console.log("Product updated:", result.data.updateProduct);

      NotificationManager.success(
        "Product information saved successfully",
        "Success"
      );
    } catch (error) {
      console.error("Error updating product", error);
      NotificationManager.error("Error updating product", "Error");
    }
  };

  const generateUUID = () => {
    var d = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  const uploadImageToS3 = async (imageFile) => {
    const result = await uploadData({
      key: `product-images/${productBrandName}-${name}-${generateUUID()}.jpg`,
      data: imageFile,
      options: {
        contentType: "image/jpeg",
        accessLevel: "guest",
        onProgress: ({ transferredBytes, totalBytes }) => {
          if (totalBytes) {
            console.log(
              `Upload progress ${
                Math.round(transferredBytes / totalBytes) * 100
              } %`
            );
          }
        }
      }
    }).result;

    const getUrlResult = await getUrl({
      key: result.key
    });

    const cleanUrl = getUrlResult.url.toString().split("?")[0];

    console.log("image URL: ", cleanUrl);
    return cleanUrl;
  };

  const addNewImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const imageUrl = await uploadImageToS3(file);
        const result = await client.graphql({
          query: createImage,
          variables: {
            input: {
              url: imageUrl,
              productId: productId
            }
          }
        });

        const createdImage = result.data.createImage;
        setImages((prevImages) => [...prevImages, createdImage]);
      } catch (error) {
        console.error("Error uploading image", error);
      }
    }
  };

  const onMakeImagePrimary = (url) => {
    console.log("onMakeImagePrimary url: " + url);
    setImageFileName(url);
  };

  const onDeleteImage = async (image) => {
    console.log("onDeleteImage id: " + image.id);
    if (images.length <= 1) {
      console.log("Product must have at least one image");
      NotificationManager.error(
        "Product must have at least one image",
        "Error"
      );
    } else if (savedDefaultImage === image.url) {
      NotificationManager.error(
        "Cannot delete image that is saved as the default image. Save first.",
        "Error"
      );
    } else {
      await client
        .graphql({
          query: deleteImage,
          variables: {
            input: {
              id: image.id
            }
          }
        })
        .then(() => {
          console.log("Image deleted");
          const tempImages = images.filter((img) => img.id !== image.id);
          setImages(tempImages);

          removeImageFromS3(image.url);
        })
        .catch(() => console.log("Error deleting image"));
    }
  };

  const removeImageFromS3 = async (url) => {
    // Extract the path after the domain
    const encodedPath = url.split("public/")[1];
    // Decode the path to handle any URL encoded characters like %20
    const path = decodeURIComponent(encodedPath);

    if (path) {
      await remove({
        key: path // TODO: after v6.2.0 use path instead of key
      });
      console.log(`Removed image at path: ${path}`);
    } else {
      console.log("Invalid URL format");
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2 style={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "1.5em",
            fontWeight: "bold",
            border: "none",
            outline: "none"
          }}
        />
      </h2>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={imageFileName}
          alt={name}
          style={{
            width: "250px",
            height: "250px",
            objectFit: "cover"
          }}
        />
        <div
          style={{
            marginLeft: "10px",
            alignSelf: "center",
            cursor: "pointer"
          }}
        >
          <h3>Add another image</h3>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={addNewImage}
            style={{ display: "none" }}
          />
          <button onClick={handleButtonClick}>Upload Image</button>
        </div>
      </div>

      <div
        style={{
          margin: 24,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center"
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              flex: "1 0 20%",
              maxWidth: "20%",
              boxSizing: "border-box",
              padding: "5px",
              border: "2px solid #ddd",
              position: "relative",
              textAlign: "center"
            }}
          >
            <img
              src={image.url}
              alt={`${index}`}
              style={{ width: "100%", height: "auto" }}
            />
            <div style={{ marginTop: "5px" }}>
              {imageFileName !== image.url && (
                <>
                  <button onClick={() => onMakeImagePrimary(image.url)}>
                    Default
                  </button>
                  <button
                    style={{ marginTop: "5px" }}
                    onClick={() => onDeleteImage(image)}
                  >
                    Delete
                  </button>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: 32, marginBottom: 32 }}>
        <p style={{ textAlign: "center" }}>
          <label style={{ marginRight: "16px" }}>
            Brand search:
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
              style={{ padding: "8px", marginRight: "8px" }}
            />
            <button onClick={handleSearch}>Search</button>
          </label>
        </p>
        {searchedBrands && searchedBrands.length > 0 && (
          <p style={{ textAlign: "center" }}>
            <strong>Searched Brands:</strong>
            {searchedBrands.map((brand) => (
              <button
                key={brand.id}
                onClick={() => {
                  setBrandId(brand.id);
                  setBrandName(brand.name);
                  setProductBrandName(brand.name);
                  setSearchedBrands(null);
                  setSearchTerm("");
                }}
                style={{
                  margin: "5px",
                  padding: "8px",
                  fontSize: "1em",
                  backgroundColor: "#ccc",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer"
                }}
              >
                {brand.name}
              </button>
            ))}
          </p>
        )}
      </div>
      <p>
        <strong>Brand:</strong> {brandName}
      </p>
      <p>
        <strong>Category:</strong>{" "}
        <select
          value={categoryId}
          onChange={(e) => onSetMainCategory(e.target.value)}
        >
          {mainCategories.map((category) => (
            <option key={category.value} value={category.value}>
              {category.label}
            </option>
          ))}
        </select>
      </p>
      <p>
        <strong>Subcategory:</strong>{" "}
        <select
          value={subcategoryId}
          onChange={(e) => onSetSubCategory(e.target.value)}
        >
          {subCategories.map((subcategory) => (
            <option key={subcategory.value} value={subcategory.value}>
              {subcategory.label}
            </option>
          ))}
        </select>
      </p>
      <p>
        <strong>Subsubcategory:</strong>{" "}
        <select
          value={subsubcategoryId}
          onChange={(e) => setSubsubcategoryId(e.target.value)}
        >
          {subSubCategories.map((subsubcategory) => (
            <option key={subsubcategory.value} value={subsubcategory.value}>
              {subsubcategory.label}
            </option>
          ))}
        </select>
      </p>
      <p>
        <strong>ID:</strong> {productId}
      </p>
      <label>
        <strong>Search terms for scanning:</strong>
      </label>
      <input
        type="text"
        value={fullText}
        onChange={(e) => setFullText(e.target.value)}
        placeholder="Seperate with spaces (Term1 Term2)"
        style={{ width: "100%", padding: "8px", marginBottom: "20px" }}
      />
      <p style={{ marginTop: 0 }}>
        <strong>Status:</strong>{" "}
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          style={{
            padding: "8px",
            fontSize: "1em",
            width: "100%",
            marginBottom: "20px"
          }}
        >
          <option value="APPROVED">APPROVED</option>
          <option value="PENDING">PENDING</option>
          <option value="REJECTED">REJECTED</option>
          <option value="DISABLED">DISABLED</option>
        </select>
      </p>
      <button
        onClick={handleSave}
        style={{
          padding: "10px",
          fontSize: "1em",
          backgroundColor: "#ff7d69",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer"
        }}
      >
        Save
      </button>

      {reviews.length > 0 && (
        <div
          style={{
            marginTop: "20px",
            borderTop: "1px solid #ddd",
            paddingTop: "20px"
          }}
        >
          <h3 style={{ marginBottom: "10px", color: "#333" }}>
            Product Reviews:
          </h3>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "10px 0",
                    textAlign: "left"
                  }}
                >
                  ID
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "10px 0",
                    textAlign: "left"
                  }}
                >
                  Text
                </th>
              </tr>
            </thead>
            <tbody>
              {reviews.map((review) => (
                <tr key={review.id} style={{ cursor: "pointer" }}>
                  <td
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "10px 0"
                    }}
                  >
                    <Link
                      to={`/reviews/${review.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {review.id}
                    </Link>
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid #ddd",
                      padding: "10px 0"
                    }}
                  >
                    <Link
                      to={`/reviews/${review.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {review.text}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <NotificationContainer />
    </div>
  );
};

export default ProductPage;
