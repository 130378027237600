import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFeedback } from "../graphql-use/queries";
import { generateClient } from "aws-amplify/api";

const FeedbackPage = () => {
  const { feedbackId } = useParams();
  const [feedback, setFeedback] = useState(null);

  const client = generateClient();

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const result = await client.graphql({
          query: getFeedback,
          variables: { id: feedbackId }
        });
        const fetchedFeedback = result.data.getFeedback;
        setFeedback(fetchedFeedback);
      } catch (error) {
        console.error("Error fetching feedback", error);
      }
    };

    fetchFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackId]);

  if (!feedback) {
    return <div>Loading...</div>;
  }

  // Function to format date as DD/MM/YYYY
  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2 style={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}>
        Feedback
      </h2>
      <p>
        <strong>Feedback:</strong> {feedback.text}
      </p>
      <p>
        <strong>Sender:</strong> {feedback.userId}
      </p>
      <p>
        <strong>Date:</strong> {formatDate(feedback.createdAt)}
      </p>
    </div>
  );
};

export default FeedbackPage;
