import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getReview } from "../graphql-use/queries";
import { updateReview } from "../graphql-use/mutations"; // Import the updateReview mutation
import { generateClient } from "aws-amplify/api";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

const ReviewPage = () => {
  const { reviewId } = useParams();
  const [review, setReview] = useState(null);
  const [newProductId, setNewProductId] = useState("");

  const client = generateClient();

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const result = await client.graphql({
          query: getReview,
          variables: { id: reviewId }
        });
        const fetchedReview = result.data.getReview;

        // Set the entire review for display purposes
        setReview(fetchedReview);
      } catch (error) {
        console.error("Error fetching review", error);
        NotificationManager.error("Error fetching review", "Error");
      }
    };

    fetchReview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewId]);

  const handleProductIdChange = (event) => {
    setNewProductId(event.target.value);
  };

  const handleUpdateProductId = async () => {
    try {
      const result = await client.graphql({
        query: updateReview,
        variables: {
          input: {
            id: reviewId,
            productId: newProductId
          }
        }
      });

      const updatedReview = result.data.updateReview;

      setReview(updatedReview);
      NotificationManager.success("Product ID updated successfully", "Success");
    } catch (error) {
      console.error("Error updating product ID", error);
      NotificationManager.error("Error updating product ID", "Error");
    }
  };

  if (!review) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2 style={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}>
        Review Details
      </h2>
      <p>
        <strong>ID:</strong> {review.id}
      </p>
      <p>
        <strong>Text:</strong> {review.text}
      </p>
      <p>
        <strong>Rating:</strong> {review.rating}
      </p>
      <p>
        <strong>Product ID:</strong> {review.productId}
      </p>
      <p>
        <strong>User ID:</strong> {review.userId}
      </p>

      <div style={{ marginTop: "62px" }}>
        <h2 style={{ marginBottom: "20px" }}>Move review to another product</h2>
        <label style={{ marginRight: "10px" }}>
          New Product ID:
          <input
            type="text"
            value={newProductId}
            onChange={handleProductIdChange}
            style={{ marginLeft: "10px", padding: "8px" }}
          />
        </label>
        <button onClick={handleUpdateProductId} style={{ padding: "8px" }}>
          Update Product ID
        </button>
      </div>

      <NotificationContainer />
    </div>
  );
};

export default ReviewPage;
