import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { getCurrentUser } from "aws-amplify/auth";
import { listCategories, searchBrands } from "../graphql-use/queries";
import { createImage, createProduct } from "../graphql-use/mutations";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { getUrl, uploadData } from "aws-amplify/storage";
import Resizer from "react-image-file-resizer";

const AddProduct = () => {
  const [userId, setUserId] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [productBrandName, setProductBrandName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [brandId, setBrandId] = useState("");
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subcategoryId, setSubcategoryId] = useState("");
  const [subsubcategoryId, setSubsubcategoryId] = useState("");
  const [status, setStatus] = useState("APPROVED");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedBrands, setSearchedBrands] = useState(null);
  const [fullText, setFullText] = useState("");

  const client = generateClient();

  useEffect(() => {
    queryCategories();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = async () => {
    try {
      const { userId } = await getCurrentUser();
      setUserId(userId);
    } catch (err) {
      console.log(err);
    }
  };

  const queryCategories = async () => {
    console.log("Query list categories ->");

    try {
      const result = await client.graphql({
        query: listCategories
      });
      const tempCategories = result.data.listCategories.items;
      const language = "en";

      const processedCategories = tempCategories
        .map((category) => {
          if (category.hidden) {
            return null;
          }

          const translation = category.translations.find(
            (t) => t.language === language
          );
          const categoryName = translation ? translation.name : category.name;

          const processedSubcategories = category.Subcategories.items
            .map((subcategory) => {
              if (subcategory.hidden) {
                return null;
              }

              const subcategoryTranslation = subcategory.translations.find(
                (t) => t.language === language
              );
              const subcategoryName = subcategoryTranslation
                ? subcategoryTranslation.name
                : subcategory.name;

              const processedSubsubcategories =
                subcategory.Subsubcategories.items
                  .map((subsubcategory) => {
                    if (subsubcategory.hidden) {
                      return null;
                    }

                    const subsubcategoryTranslation =
                      subsubcategory.translations.find(
                        (t) => t.language === language
                      );
                    const subsubcategoryName = subsubcategoryTranslation
                      ? subsubcategoryTranslation.name
                      : subsubcategory.name;

                    return {
                      id: subsubcategory.id,
                      name: subsubcategoryName,
                      subcategoryID: subsubcategory.subcategoryID,
                      iconName: subsubcategory.name,
                      order: subsubcategory.order
                    };
                  })
                  .filter(Boolean);

              const sortedSubSubCategories = processedSubsubcategories.sort(
                (a, b) => a.order - b.order
              );

              return {
                id: subcategory.id,
                name: subcategoryName,
                Subsubcategories: sortedSubSubCategories,
                categoryID: subcategory.categoryID,
                iconName: subcategory.name,
                order: subcategory.order
              };
            })
            .filter(Boolean);

          const sortedSubCategories = processedSubcategories.sort(
            (a, b) => a.order - b.order
          );

          return {
            id: category.id,
            name: categoryName,
            order: category.order,
            Subcategories: sortedSubCategories
          };
        })
        .filter(Boolean);

      const sortedCategories = processedCategories.sort(
        (a, b) => a.order - b.order
      );

      setCategories(sortedCategories);
      createMainCategoryList(sortedCategories);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const createMainCategoryList = (categories) => {
    console.log("createMainCategoryList");
    const mainCategoriesTemp = [];
    categories.forEach((cat) => {
      mainCategoriesTemp.push({
        label: cat.name,
        value: cat.id
      });
    });
    setMainCategories(mainCategoriesTemp);
  };

  const createSubCategoryList = (categories, mainCategoryId) => {
    console.log("createSubCategoryList");
    const subCategoriesTemp = [];
    categories.forEach((cat) => {
      if (cat.id === mainCategoryId) {
        cat.Subcategories.forEach((subCat) => {
          subCategoriesTemp.push({
            label: subCat.name,
            value: subCat.id
          });
        });
      }
    });
    setSubCategories(subCategoriesTemp);
  };

  const createSubSubCategoryList = (
    categories,
    mainCategoryId,
    subCategoryId
  ) => {
    console.log("createSubSubCategoryList");
    const subSubCategoriesTemp = [];
    categories.forEach((cat) => {
      if (cat.id === mainCategoryId) {
        cat.Subcategories.forEach((subCat) => {
          if (subCat.id === subCategoryId) {
            subCat.Subsubcategories.forEach((subSubCat) => {
              subSubCategoriesTemp.push({
                label: subSubCat.name,
                value: subSubCat.id
              });
            });
          }
        });
      }
    });
    setSubSubCategories(subSubCategoriesTemp);
  };

  const onSetMainCategory = (mainCategory) => {
    setCategoryId(mainCategory);
    setSubcategoryId("");
    setSubsubcategoryId("");
    createSubCategoryList(categories, mainCategory);
  };

  useEffect(() => {
    if (categoryId) {
      createSubCategoryList(categories, categoryId);
    }
  }, [categoryId, categories]);

  const onSetSubCategory = (subCategory) => {
    setSubcategoryId(subCategory);
    setSubsubcategoryId("");
    createSubSubCategoryList(categories, categoryId, subCategory);
  };

  useEffect(() => {
    if (subcategoryId) {
      createSubSubCategoryList(categories, categoryId, subcategoryId);
    }
  }, [subcategoryId, categoryId, categories]);

  const handleSave = async () => {
    try {
      let imageUrl = "";
      if (imageFile) {
        imageUrl = await uploadImageToS3(imageFile);
      }

      const tempFullText = productBrandName + " " + name + " " + fullText;
      const processedFullText = tempFullText
        .replace(/[ÄÀÁÂÃÅ]/g, "A")
        .replace(/[äàáâãå]/g, "a")
        .replace(/[ÈÉÊË]/g, "E")
        .replace(/[èéêë]/g, "e")
        .replace(/[ÌÍÎÏ]/g, "I")
        .replace(/[ìíîï]/g, "i")
        .replace(/[ÒÓÔÕÖ]/g, "O")
        .replace(/[òóôõö]/g, "o")
        .replace(/[ÙÚÛÜ]/g, "U")
        .replace(/[ùúûü]/g, "u")
        .replace(/[ÝŸ]/g, "Y")
        .replace(/[ýÿ]/g, "y");

      const input = {
        name,
        imageFileName: imageUrl,
        brandName: productBrandName,
        brandId: brandId,
        categoryId,
        subcategoryID: subcategoryId,
        subsubcategoryID: subsubcategoryId,
        status,
        interactionsScore: 0,
        fullText: processedFullText,
        userId: userId
      };
      console.log("input" + JSON.stringify(input));

      const result = await client.graphql({
        query: createProduct,
        variables: { input }
      });

      console.log("Product created:", result.data.createProduct);

      await client
        .graphql({
          query: createImage,
          variables: {
            input: {
              url: imageUrl,
              productId: result.data.createProduct.id
            }
          }
        })
        .then(() => console.log("Image created"))
        .catch(() => console.log("Error creating image"));

      NotificationManager.success("Product added successfully", "Success");
    } catch (error) {
      console.error("Error adding product", error);
      NotificationManager.error("Error adding product", "Error");
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    setImageFile(image);

    // Read the file and set the image preview
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(image);
  };

  const generateUUID = () => {
    var d = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  const uploadImageToS3 = async (imageFile) => {
    const result = await uploadData({
      key: `product-images/${productBrandName}-${name}-${generateUUID()}.jpg`,
      data: imageFile,
      options: {
        contentType: "image/jpeg",
        accessLevel: "guest",
        onProgress: ({ transferredBytes, totalBytes }) => {
          if (totalBytes) {
            console.log(
              `Upload progress ${
                Math.round(transferredBytes / totalBytes) * 100
              } %`
            );
          }
        }
      }
    }).result;

    const getUrlResult = await getUrl({
      key: result.key
    });

    const cleanUrl = getUrlResult.url.toString().split("?")[0];

    console.log("image URL: ", cleanUrl);
    return cleanUrl;
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = async () => {
    console.log("Search " + searchTerm);
    const result = await client.graphql({
      query: searchBrands,
      variables: {
        filter: {
          name: {
            matchPhrasePrefix: searchTerm.trim().toLowerCase()
          }
        },
        limit: 4
      }
    });
    console.log("result " + JSON.stringify(result));
    setSearchedBrands(result.data.searchBrands.items);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2 style={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}>
        Add Product
      </h2>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Product Name"
        style={{ width: "100%", padding: "8px", marginBottom: "20px" }}
      />
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <img
          src={imagePreview}
          alt={name}
          style={{
            width: "250px",
            height: "250px",
            objectFit: "cover",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "black"
          }}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{
            marginLeft: "10px",
            alignSelf: "center",
            cursor: "pointer"
          }}
        />
      </div>
      <div style={{ marginTop: 32, marginBottom: 32 }}>
        <p style={{ textAlign: "center" }}>
          <label style={{ marginRight: "16px" }}>
            Brand search:
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
              style={{ padding: "8px", marginRight: "8px" }}
            />
            <button onClick={handleSearch}>Search</button>
          </label>
        </p>
        {searchedBrands && searchedBrands.length > 0 && (
          <p style={{ textAlign: "center" }}>
            <strong>Searched Brands:</strong>
            {searchedBrands.map((brand) => (
              <button
                key={brand.id}
                onClick={() => {
                  setBrandId(brand.id);
                  setBrandName(brand.name);
                  setProductBrandName(brand.name);
                  setSearchedBrands(null);
                  setSearchTerm("");
                }}
                style={{
                  margin: "5px",
                  padding: "8px",
                  fontSize: "1em",
                  backgroundColor: "#ccc",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer"
                }}
              >
                {brand.name}
              </button>
            ))}
          </p>
        )}
      </div>
      <p>
        <strong>Brand:</strong> {brandName}
      </p>
      <p>
        <strong>Category:</strong>{" "}
        <select
          value={categoryId}
          onChange={(e) => onSetMainCategory(e.target.value)}
        >
          <option value="">Select Category</option>
          {mainCategories.map((category) => (
            <option key={category.value} value={category.value}>
              {category.label}
            </option>
          ))}
        </select>
      </p>
      <p>
        <strong>Subcategory:</strong>{" "}
        <select
          value={subcategoryId}
          onChange={(e) => onSetSubCategory(e.target.value)}
        >
          <option value="">Select Subcategory</option>
          {subCategories.map((subcategory) => (
            <option key={subcategory.value} value={subcategory.value}>
              {subcategory.label}
            </option>
          ))}
        </select>
      </p>
      <p>
        <strong>Subsubcategory:</strong>{" "}
        <select
          value={subsubcategoryId}
          onChange={(e) => setSubsubcategoryId(e.target.value)}
        >
          <option value="">Select Subsubcategory</option>
          {subSubCategories.map((subsubcategory) => (
            <option key={subsubcategory.value} value={subsubcategory.value}>
              {subsubcategory.label}
            </option>
          ))}
        </select>
      </p>
      <label>
        <strong>Additional search terms for scanning:</strong>
      </label>
      <input
        type="text"
        value={fullText}
        onChange={(e) => setFullText(e.target.value)}
        placeholder="Seperate with spaces (Term1 Term2)"
        style={{ width: "100%", padding: "8px", marginBottom: "20px" }}
      />
      <select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        style={{ width: "100%", padding: "8px", marginBottom: "20px" }}
      >
        <option value="APPROVED">APPROVED</option>
        <option value="PENDING">PENDING</option>
        <option value="REJECTED">REJECTED</option>
        <option value="DISABLED">DISABLED</option>
      </select>
      <button
        onClick={handleSave}
        style={{
          padding: "10px",
          fontSize: "1em",
          backgroundColor: "#ff7d69",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer"
        }}
      >
        Save
      </button>
      <NotificationContainer />
    </div>
  );
};

export default AddProduct;
