import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App";
import { ThemeProvider, createTheme } from "@mui/material";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ff7d69",
      light: "#ffebd9"
    },
    background: {
      default: "#FFFFFF",
      paper: "#ffebd9"
    },
    grey: {
      100: "#ebedef",
      900: "#050C23"
    },
    success: {
      main: "#4BB543"
    },
    error: {
      main: "#FF9494"
    }
  },
  typography: {
    h1: {
      fontSize: 26,
      fontWeight: "bold"
    },
    h2: {
      fontSize: 22,
      fontWeight: "bold"
    },
    h3: {
      fontSize: 20,
      fontWeight: "bold"
    },
    h4: {
      fontSize: 18,
      fontWeight: "bold"
    },
    h5: {
      fontSize: 16,
      fontWeight: "bold"
    },
    h6: {
      fontSize: 14,
      fontWeight: "bold"
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </ThemeProvider>
);
