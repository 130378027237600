import React from "react";
import PermanentDrawerLeft from "./PermanentDrawerLeft";
import { Container } from "@mui/material";
import { Amplify } from "aws-amplify";
import awsconfig from "../aws-exports";

Amplify.configure(awsconfig);

function Home() {
  return (
    <Container
      maxWidth={false}
      sx={{ bgcolor: "grey.100", height: "auto", m: 0 }}
    >
      <PermanentDrawerLeft />
    </Container>
  );
}

export default Home;
