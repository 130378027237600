import React, { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { listReviews } from "../graphql-use/queries";
import { Link } from "react-router-dom";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterProductId, setFilterProductId] = useState("");

  const client = generateClient();

  useEffect(() => {
    fetchReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReviews = async () => {
    try {
      const result = await client.graphql({
        query: listReviews
      });
      setReviews(result.data.listReviews.items);
    } catch (error) {
      console.error("Error fetching reviews", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterProductIdChange = (event) => {
    setFilterProductId(event.target.value);
  };

  const filteredReviews = reviews
    .filter((review) => review.text) // Only include reviews with text
    .filter((review) =>
      review.text.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((review) =>
      filterProductId ? review.productId === filterProductId : true
    );

  return (
    <div style={{ margin: "20px" }}>
      <div>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <label style={{ marginRight: "16px" }}>
            Search:
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              style={{ padding: "8px", marginRight: "8px" }}
            />
          </label>
          <label style={{ marginRight: "16px" }}>
            Filter by Product ID:
            <input
              type="text"
              value={filterProductId}
              onChange={handleFilterProductIdChange}
              style={{ padding: "8px", marginRight: "8px" }}
            />
          </label>
          <button onClick={fetchReviews} style={{ padding: "8px" }}>
            Refresh
          </button>
        </div>

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Review Text
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Rating
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Product ID
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                User ID
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Review ID
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredReviews.map((review) => (
              <tr key={review.id} style={{ cursor: "pointer" }}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/reviews/${review.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {review.text}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/reviews/${review.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {review.rating}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/reviews/${review.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {review.productId}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/reviews/${review.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {review.userId}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/reviews/${review.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {review.id}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Reviews;
