import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { listBrands } from "../graphql-use/queries";

const Brands = () => {
  const [brands, setBrands] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const navigate = useNavigate();

  const client = generateClient();

  useEffect(() => {
    fetchBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBrands = async () => {
    try {
      const result = await client.graphql({
        query: listBrands,
        variables: {
          limit: 2000
        }
      });
      setBrands(result.data.listBrands.items);
    } catch (error) {
      console.error("Error fetching brands", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleAddBrand = () => {
    navigate("/add-brand");
  };

  const filteredBrands = brands.filter((brand) => {
    if (filter === "all") {
      return true;
    } else {
      return brand.status === filter;
    }
  });

  const searchedBrands = filteredBrands.filter((brand) =>
    brand.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ margin: "20px" }}>
      <div>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div>
            <label style={{ marginRight: "16px" }}>
              Search:
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                style={{ padding: "8px", marginRight: "8px" }}
              />
            </label>
            <label style={{ marginRight: "16px" }}>
              Filter:
              <select
                value={filter}
                onChange={handleFilterChange}
                style={{ padding: "8px", marginRight: "8px" }}
              >
                <option value="all">All</option>
                <option value="APPROVED">Approved</option>
                <option value="PENDING">Pending</option>
                <option value="REJECTED">Rejected</option>
                <option value="DISABLED">Disabled</option>
              </select>
            </label>
            <button onClick={fetchBrands} style={{ padding: "8px" }}>
              Refresh
            </button>
          </div>
          <button
            onClick={handleAddBrand}
            style={{ padding: "8px", marginLeft: "16px" }}
          >
            Add brand
          </button>
        </div>

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Name
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                ID
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {searchedBrands.map((brand) => (
              <tr key={brand.id} style={{ cursor: "pointer" }}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/brands/${brand.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {brand.name}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/brands/${brand.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {brand.id}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/brands/${brand.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {brand.status}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Brands;
