import React, { useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { generateClient } from "aws-amplify/api";
import { listAdvertisements } from "../graphql-use/queries";
import {
  updateAdvertisement,
  createAdvertisement,
  deleteAdvertisement
} from "../graphql-use/mutations";
import { remove, uploadData, getUrl } from "aws-amplify/storage";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import { v4 as uuidv4 } from "uuid";

const Ad = ({ ad, moveAd, findAd, updateAdLink, onDeleteAd }) => {
  const originalIndex = findAd(ad.id).index;
  const [editableLink, setEditableLink] = useState(ad.link);
  const client = generateClient();

  const [, drag] = useDrag({
    type: "AD",
    item: { id: ad.id, originalIndex }
  });

  const [, drop] = useDrop({
    accept: "AD",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.originalIndex;
      const hoverIndex = findAd(ad.id).index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveAd(dragIndex, hoverIndex);
      item.originalIndex = hoverIndex;
    }
  });

  const handleDeleteAd = async (ad) => {
    try {
      // Delete advertisement using GraphQL
      await client.graphql({
        query: deleteAdvertisement,
        variables: {
          input: {
            id: ad.id
          }
        }
      });

      // Remove associated file from S3
      await remove({ key: ad.imageLocation });

      // Trigger the onDeleteAd function to update the local state
      onDeleteAd(ad.id);

      console.log("Advertisement deleted successfully");
    } catch (error) {
      console.error("Error deleting advertisement", error);
    }
  };

  const ref = React.useRef();
  drag(drop(ref));

  useEffect(() => {
    // Automatically update the link when the component mounts
    updateAdLink(ad.id, editableLink);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableLink]);

  return (
    <tr
      ref={ref}
      key={ad.id}
      style={{ ...styles.tableRow, backgroundColor: "#f9f9f9" }}
    >
      <td style={styles.tableCell}>{ad.id}</td>
      <td style={styles.tableCell}>
        <input
          type="text"
          value={editableLink}
          onChange={(e) => setEditableLink(e.target.value)}
          style={{ width: "100%" }}
        />
      </td>
      <td style={styles.tableCell}>
        <img
          src={ad.imageLocation}
          alt={`Ad ${ad.id}`}
          style={styles.adImage}
        />
      </td>
      <td style={styles.tableCell}>
        <button onClick={() => handleDeleteAd(ad)} style={styles.deleteButton}>
          Delete
        </button>
      </td>
    </tr>
  );
};

const AdsManager = () => {
  const [ads, setAds] = useState([]);
  const [newAdLink, setNewAdLink] = useState("");
  const [newAdImage, setNewAdImage] = useState(null);

  const client = generateClient();

  useEffect(() => {
    fetchAds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAds = async () => {
    try {
      const result = await client.graphql({
        query: listAdvertisements
      });
      const sortedAds = result.data.listAdvertisements.items.sort(
        (a, b) => a.order - b.order
      );
      setAds(sortedAds.map((ad, index) => ({ ...ad, index })));
    } catch (error) {
      console.error("Error fetching ads", error);
    }
  };

  const handleDeleteAd = (deletedAdId) => {
    // Update the local state by filtering out the deleted ad
    setAds((prevAds) => prevAds.filter((prevAd) => prevAd.id !== deletedAdId));
  };

  const handleAddAd = async () => {
    if (!newAdImage) {
      NotificationManager.error("Please provide image for the new ad", "Error");
      return;
    }

    try {
      // Upload the image to S3
      const imageLocation = await uploadImageToS3(newAdImage);
      console.log("imageLocation: " + imageLocation);

      // Create a new ad in the database
      const result = await client.graphql({
        query: createAdvertisement,
        variables: {
          input: {
            link: newAdLink,
            imageLocation: imageLocation,
            order: ads.length + 1 // Place the new ad at the end
          }
        }
      });

      // Update the local state with the new ad
      const newAd = result.data.createAdvertisement;
      setAds([...ads, { ...newAd, index: ads.length }]);

      NotificationManager.success("New ad added successfully", "Success");

      // Clear the input fields
      setNewAdLink("");
      setNewAdImage(null);
    } catch (error) {
      console.error("Error adding new ad", error);
      NotificationManager.error("Error adding new ad", "Error");
    }
  };

  const uploadImageToS3 = async (imageFile) => {
    const result = await uploadData({
      key: `advertisements/ad-${uuidv4()}.jpg`,
      data: imageFile,
      options: {
        contentType: "image/jpeg",
        accessLevel: "guest",
        onProgress: ({ transferredBytes, totalBytes }) => {
          if (totalBytes) {
            console.log(
              `Upload progress ${
                Math.round(transferredBytes / totalBytes) * 100
              } %`
            );
          }
        }
      }
    }).result;

    const getUrlResult = await getUrl({
      key: result.key
    });

    const cleanUrl = getUrlResult.url.toString().split("?")[0];

    console.log("signed URL: ", cleanUrl);
    return cleanUrl;
  };

  const moveAd = (fromIndex, toIndex) => {
    const updatedAds = [...ads];
    const [movedAd] = updatedAds.splice(fromIndex, 1);
    updatedAds.splice(toIndex, 0, movedAd);

    // Update the order property based on the new index
    const updatedAdsWithOrder = updatedAds.map((ad, index) => ({
      ...ad,
      order: index + 1
    }));

    setAds(updatedAdsWithOrder);
  };

  const findAd = (id) => {
    const ad = ads.find((ad) => ad.id === id);
    return {
      ad,
      index: ad ? ad.index : null
    };
  };

  const updateAdLink = (adId, newLink) => {
    setAds((prevAds) =>
      prevAds.map((ad) => (ad.id === adId ? { ...ad, link: newLink } : ad))
    );
  };

  const handleSaveOrder = async () => {
    try {
      await Promise.all(
        ads.map(async (ad, index) => {
          await client.graphql({
            query: updateAdvertisement,
            variables: {
              input: {
                id: ad.id,
                order: index + 1,
                link: ad.link
              }
            }
          });
        })
      );
      NotificationManager.success(
        "Order and links saved successfully",
        "Success"
      );
    } catch (error) {
      console.error("Error saving order and links", error);
      NotificationManager.error("Error saving order and links", "Error");
    }
  };

  return (
    <div style={styles.container}>
      <h2>Advertisements</h2>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.tableHeader}>ID</th>
            <th style={styles.tableHeader}>Link</th>
            <th style={styles.tableHeader}>Image</th>
            <th style={styles.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {ads.map((ad) => (
            <Ad
              key={ad.id}
              id={ad.id}
              ad={ad}
              moveAd={moveAd}
              findAd={findAd}
              updateAdLink={updateAdLink}
              onDeleteAd={handleDeleteAd} // Pass the function to the Ad component
            />
          ))}
        </tbody>
      </table>
      <button onClick={handleSaveOrder} style={styles.saveButton}>
        Save Order and Links
      </button>
      <div style={styles.addAdContainer}>
        <h3>Add New Ad</h3>
        <input
          type="text"
          value={newAdLink}
          onChange={(e) => setNewAdLink(e.target.value)}
          style={styles.input}
          placeholder="Enter link..."
        />
        <input
          type="file"
          onChange={(e) => setNewAdImage(e.target.files[0])}
          style={styles.input}
          accept="image/*"
        />
        <button onClick={handleAddAd} style={styles.addButton}>
          Add Ad
        </button>
      </div>
      <NotificationContainer />
    </div>
  );
};

const styles = {
  container: {
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "10px"
  },
  tableHeader: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f2f2f2"
  },
  tableRow: {
    cursor: "pointer"
  },
  tableCell: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center"
  },
  adImage: {
    maxHeight: "120px"
  },
  deleteButton: {
    padding: "5px 10px",
    cursor: "pointer",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "4px"
  },
  addAdContainer: {
    marginTop: "20px"
  },
  input: {
    marginRight: "10px",
    padding: "5px"
  },
  addButton: {
    padding: "5px 10px",
    cursor: "pointer"
  },
  saveButton: {
    marginTop: 12,
    marginLeft: "auto",
    padding: "10px",
    fontSize: "1em",
    backgroundColor: "#ff7d69",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer"
  }
};

export default AdsManager;
