import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Button } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate
} from "react-router-dom";
import Products from "./Products";
import Brands from "./Brands";
import Categories from "./Categories";
import Reviews from "./Reviews";
import Users from "./Users";
import Dashboard from "./Dashboard";
import { signOut } from "aws-amplify/auth";
import ProductPage from "./ProductPage";
import AdsManager from "./AdsManager";
import UserPage from "./UserPage";
import ReviewPage from "./ReviewPage";
import BrandPage from "./BrandPage";
import NewBrandPage from "./NewBrandPage";
import Feedback from "./Feedback";
import FeedbackPage from "./FeedbackPage";
import AddProduct from "./AddProduct";
import Collections from "./Collections";
import CollectionPage from "./CollectionPage";
import NewCollectionPage from "./NewCollectionPage";

const drawerWidth = 240;

export default function PermanentDrawerLeft() {
  const [title, setTitle] = React.useState("Dashboard");

  const handleLogout = async () => {
    try {
      await signOut();
      console.log("Logout successful!");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Router>
      <Box sx={{ display: "flex", bgcolor: "grey.100" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
            bgcolor: "white"
          }}
        >
          <Toolbar>
            <Typography variant="h2" noWrap component="div" sx={{ flex: 1 }}>
              {title}
            </Typography>
            <Button onClick={handleLogout}>Logout</Button>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box"
            }
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar sx={{ bgcolor: "primary.main" }}>
            <Typography
              variant="h1"
              sx={{ fontWeight: "bold", color: "white" }}
            >
              Cosmate admin
            </Typography>
          </Toolbar>
          <Divider />
          <List>
            {["Dashboard"].map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                component={Link}
                to="/dashboard"
                onClick={() => setTitle(text)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ color: "black" }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Typography
            variant="h6"
            sx={{ color: "grey.900", paddingLeft: 1, paddingTop: 1 }}
          >
            EDIT CONTENT
          </Typography>
          <List>
            {[
              "Products",
              "Brands",
              "Categories",
              "Reviews",
              "Ads",
              "Collections"
            ].map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                component={Link}
                to={`/${text.toLowerCase()}`}
                onClick={() => setTitle(text)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ color: "black" }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {["Users", "Feedback"].map((text, index) => (
              <ListItem
                key={text}
                disablePadding
                component={Link}
                to={`/${text.toLowerCase()}`}
                onClick={() => setTitle(text)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ color: "black" }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Toolbar />
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/products" element={<Products />} />
            <Route path="/products/:productId" element={<ProductPage />} />
            <Route path="/brands" element={<Brands />} />
            <Route path="/brands/:brandId" element={<BrandPage />} />
            <Route path="/add-brand" element={<NewBrandPage />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/reviews/:reviewId" element={<ReviewPage />} />
            <Route path="/ads" element={<AdsManager />} />
            <Route path="/collections" element={<Collections />} />
            <Route
              path="/collections/:collectionId"
              element={<CollectionPage />}
            />
            <Route path="/add-collection" element={<NewCollectionPage />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/:userId" element={<UserPage />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/feedback/:feedbackId" element={<FeedbackPage />} />
            <Route path="/add-product" element={<AddProduct />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}
