/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "fc10bba9c8db4131a448d1493cf5e479",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "fc10bba9c8db4131a448d1493cf5e479",
            "region": "eu-central-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://gioflmi3mzhghetgzxzstnxcqi.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2guepp3jdvf7df3yjrej5gw36e",
    "aws_cognito_identity_pool_id": "eu-central-1:e84d70ad-3e55-4aec-9cc1-e28e5d617c0a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_iN0qmE9L7",
    "aws_user_pools_web_client_id": "bkesk50q4sua6sh5vgd8dhfsh",
    "oauth": {
        "domain": "9ehf7hdcidc0-test.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "cosmate://",
        "redirectSignOut": "cosmate://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cosmate-app-bucket180131-test",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
