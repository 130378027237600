import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { listProducts } from "../graphql-use/queries";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [brandSearchTerm, setBrandSearchTerm] = useState(""); // New state variable for brand search
  const [filter, setFilter] = useState("all");
  const navigate = useNavigate();

  const client = generateClient();

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProducts = async () => {
    try {
      const result = await client.graphql({
        query: listProducts,
        variables: {
          limit: 2000
        }
      });
      setProducts(result.data.listProducts.items);
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleBrandSearch = (event) => {
    setBrandSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredProducts = products.filter((product) => {
    if (filter === "all") {
      return true;
    } else {
      return product.status === filter;
    }
  });

  const searchedProducts = filteredProducts
    .filter(
      (product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        product.brandName.toLowerCase().includes(brandSearchTerm.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name));

  const handleAddProduct = () => {
    navigate("/add-product");
  };

  return (
    <div style={{ margin: "20px" }}>
      <div>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div>
            <label style={{ marginRight: "16px" }}>
              Search name:
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                style={{ padding: "8px", marginRight: "8px" }}
              />
            </label>
            <label style={{ marginRight: "16px" }}>
              Search brand:
              <input
                type="text"
                value={brandSearchTerm}
                onChange={handleBrandSearch}
                style={{ padding: "8px", marginRight: "8px" }}
              />
            </label>
            <label style={{ marginRight: "16px" }}>
              Filter:
              <select
                value={filter}
                onChange={handleFilterChange}
                style={{ padding: "8px", marginRight: "8px" }}
              >
                <option value="all">All</option>
                <option value="APPROVED">Approved</option>
                <option value="PENDING">Pending</option>
                <option value="REJECTED">Rejected</option>
                <option value="DISABLED">Disabled</option>
              </select>
            </label>
            <button onClick={fetchProducts} style={{ padding: "8px" }}>
              Refresh
            </button>
          </div>
          <button onClick={handleAddProduct} style={{ padding: "8px" }}>
            Add product
          </button>
        </div>

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Name
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Brand
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                ID
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {searchedProducts.map((product) => (
              <tr key={product.id} style={{ cursor: "pointer" }}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/products/${product.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {product.name}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/products/${product.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {product.brandName}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/products/${product.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {product.id}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/products/${product.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {product.status}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Products;
