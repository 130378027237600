import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { listUsers } from "../graphql-use/queries";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const client = generateClient();

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = async () => {
    try {
      const result = await client.graphql({
        query: listUsers,
        variables: {
          limit: 2000
        }
      });
      setUsers(result.data.listUsers.items);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchedUsers = users.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ margin: "20px" }}>
      <div>
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            alignItems: "center"
          }}
        >
          <label style={{ marginRight: "16px" }}>
            Search:
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              style={{ padding: "8px", marginRight: "8px" }}
            />
          </label>
          <button onClick={fetchUsers} style={{ padding: "8px" }}>
            Refresh
          </button>
        </div>

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Username
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Email
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                ID
              </th>
            </tr>
          </thead>
          <tbody>
            {searchedUsers.map((user) => (
              <tr key={user.id} style={{ cursor: "pointer" }}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/users/${user.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {user.username}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/users/${user.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {user.email}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/users/${user.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {user.id}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
