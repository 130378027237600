import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { listFeedbacks } from "../graphql-use/queries";

const Feedback = () => {
  const [feedback, setFeedback] = useState([]);

  const client = generateClient();

  useEffect(() => {
    fetchFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFeedback = async () => {
    try {
      const result = await client.graphql({
        query: listFeedbacks
      });

      // Sort feedbacks by date
      const sortedFeedback = result.data.listFeedbacks.items.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setFeedback(sortedFeedback);
    } catch (error) {
      console.error("Error fetching feedback", error);
    }
  };

  return (
    <div style={{ margin: "20px" }}>
      <div>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Feedback
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Sender
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                ID
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  backgroundColor: "#f2f2f2"
                }}
              >
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            {feedback.map((item) => (
              <tr key={item.id} style={{ cursor: "pointer" }}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/feedback/${item.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {item.text}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/feedback/${item.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {item.userId}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/feedback/${item.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {item.id}
                  </Link>
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  <Link
                    to={`/feedback/${item.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {new Date(item.createdAt).toLocaleDateString("en-GB")}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Feedback;
