import React, { useEffect, useState } from "react";
import { listCategories } from "../graphql-use/queries";
import { generateClient } from "aws-amplify/api";

const Categories = () => {
  const [categories, setCategories] = useState([]);

  const client = generateClient();

  useEffect(() => {
    const getCategories = async () => {
      console.log("Query list categories ->");
      try {
        const list = await client.graphql({
          query: listCategories
        });

        const tempCategories = list.data.listCategories.items;
        setCategories(tempCategories);
      } catch (error) {
        console.log("Error getting categories: " + error);
      }
    };

    if (categories.length === 0) {
      getCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories.length]);

  const renderSubsubcategories = (subsubcategories) => {
    if (!subsubcategories || subsubcategories.length === 0) {
      return null;
    }

    return (
      <ul style={styles.subsubcategoryList}>
        {subsubcategories.map((subsubcategory) => (
          <li key={subsubcategory.id} style={styles.subsubcategoryItem}>
            <strong>{subsubcategory.name}</strong> ({subsubcategory.id})
          </li>
        ))}
      </ul>
    );
  };

  const renderSubcategories = (subcategories) => {
    if (!subcategories || subcategories.length === 0) {
      return null;
    }

    return (
      <ul style={styles.subcategoryList}>
        {subcategories.map((subcategory) => (
          <li key={subcategory.id} style={styles.subcategoryItem}>
            <strong>{subcategory.name}</strong> ({subcategory.id})
            {renderSubsubcategories(subcategory.Subsubcategories.items)}
          </li>
        ))}
      </ul>
    );
  };

  const renderCategories = () => {
    if (!categories || categories.length === 0) {
      return;
    }

    return (
      <div style={styles.container}>
        {categories.map((category) => (
          <div key={category.id} style={styles.categoryItem}>
            <h3>
              <strong>{category.name}</strong> ({category.id})
            </h3>
            {renderSubcategories(category.Subcategories.items)}
          </div>
        ))}
      </div>
    );
  };

  return <div>{renderCategories()}</div>;
};

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 24,
    gap: 20
  },
  categoryItem: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "16px",
    width: "calc(33.333% - 20px)",
    boxSizing: "border-box",
    backgroundColor: "#f9f9f9"
  },
  subcategoryList: {
    listStyleType: "none",
    paddingLeft: "20px",
    marginTop: "10px"
  },
  subsubcategoryList: {
    listStyleType: "none",
    paddingLeft: "20px",
    marginTop: "5px"
  },
  subcategoryItem: {
    marginBottom: "10px"
  },
  subsubcategoryItem: {
    marginBottom: "5px"
  }
};

export default Categories;
