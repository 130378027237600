import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import {
  listProducts,
  listReviews,
  listUsers,
  listBrands
} from "../graphql-use/queries";

const Dashboard = () => {
  const [productCount, setProductCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [reviewWithTextCount, setReviewWithTextCount] = useState(0);
  const [reviewWithoutTextCount, setReviewWithoutTextCount] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [brandCount, setBrandCount] = useState(0);
  const [users, setUsers] = useState([]);

  const client = generateClient();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      const [products, reviews, usersData, brands] = await Promise.all([
        client.graphql({
          query: listProducts,
          variables: {
            filter: {
              status: {
                eq: "APPROVED"
              }
            },
            limit: 10000
          }
        }),
        client.graphql({
          query: listReviews,
          variables: {
            limit: 10000
          }
        }),
        client.graphql({
          query: listUsers,
          variables: {
            limit: 10000
          }
        }),
        client.graphql({
          query: listBrands,
          variables: {
            filter: {
              status: {
                eq: "APPROVED"
              }
            },
            limit: 10000
          }
        })
      ]);

      const reviewsList = reviews.data.listReviews.items;
      const reviewsWithText = reviewsList.filter(
        (review) => review.text && review.text.trim().length > 0
      ).length;
      const reviewsWithoutText = reviewsList.length - reviewsWithText;

      setUsers(usersData.data.listUsers.items);
      setProductCount(products.data.listProducts.items.length);
      setUserCount(usersData.data.listUsers.items.length);
      setReviewCount(reviewsList.length);
      setReviewWithTextCount(reviewsWithText);
      setReviewWithoutTextCount(reviewsWithoutText);
      setBrandCount(brands.data.listBrands.items.length); // Set the brand count
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to count users by gender
  const countUsersByGender = (gender) => {
    return users.filter(
      (user) => user.gender === gender && user.dateOfBirth !== null
    ).length;
  };

  // Function to calculate percentage
  const calculatePercentage = (value, total) => {
    return ((value / total) * 100).toFixed(2);
  };

  // Function to get age from date of birth
  const getAge = (dateString) => {
    if (!dateString) return null;
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  // Function to count users by age group
  const countUsersByAgeGroup = (users, minAge, maxAge) => {
    return users.filter((user) => {
      const age = getAge(user.dateOfBirth);
      return age !== null && age >= minAge && age <= maxAge;
    }).length;
  };

  // Age groups
  const ageGroups = [
    { label: "Under 18", minAge: 0, maxAge: 17 },
    { label: "18 - 25", minAge: 18, maxAge: 25 },
    { label: "26 - 35", minAge: 26, maxAge: 35 },
    { label: "36 - 45", minAge: 36, maxAge: 45 },
    { label: "46 - 55", minAge: 46, maxAge: 55 },
    { label: "56 and Above", minAge: 56, maxAge: Infinity }
  ];

  // Total number of users
  const totalUsers = userCount;

  return (
    <div style={styles.container}>
      <div style={styles.statBoxContainer}>
        <div style={styles.statBox}>
          <h3>Products (approved)</h3>
          <p style={styles.value}>{productCount}</p>
        </div>
        <div style={styles.statBox}>
          <h3>Brands</h3>
          <p style={styles.value}>{brandCount}</p>
        </div>
        <div style={styles.statBox}>
          <h3>Reviews</h3>
          <p style={styles.value}>{reviewCount}</p>
          <p style={styles.genderStat}>
            With Text: {reviewWithTextCount} (
            {calculatePercentage(reviewWithTextCount, reviewCount)}%)
          </p>
          <p style={styles.genderStat}>
            Without Text: {reviewWithoutTextCount} (
            {calculatePercentage(reviewWithoutTextCount, reviewCount)}%)
          </p>
        </div>
        <div style={styles.statBox}>
          <h3>Users</h3>
          <p style={styles.value}>{userCount}</p>
        </div>
        <div style={styles.statBox}>
          <h3>Gender Statistics</h3>
          <p style={styles.genderStat}>
            Man: {countUsersByGender("man")} (
            {calculatePercentage(countUsersByGender("man"), totalUsers)}%)
          </p>
          <p style={styles.genderStat}>
            Woman: {countUsersByGender("woman")} (
            {calculatePercentage(countUsersByGender("woman"), totalUsers)}%)
          </p>
          <p style={styles.genderStat}>
            Other: {countUsersByGender("other")} (
            {calculatePercentage(countUsersByGender("other"), totalUsers)}%)
          </p>
        </div>
        <div style={styles.statBox}>
          <h3>Age Statistics</h3>
          {ageGroups.map((group, index) => (
            <p key={index} style={styles.genderStat}>
              {group.label}:{" "}
              {countUsersByAgeGroup(users, group.minAge, group.maxAge)} (
              {calculatePercentage(
                countUsersByAgeGroup(users, group.minAge, group.maxAge),
                totalUsers
              )}
              %)
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    marginTop: 24,
    display: "flex",
    justifyContent: "center"
  },
  statBoxContainer: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 1000,
    justifyContent: "center"
  },
  statBox: {
    flex: 1,
    backgroundColor: "#ffffff",
    borderRadius: 6,
    padding: 20,
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: 12,
    minWidth: 200,
    maxWidth: 300
  },
  value: {
    fontSize: "32px"
  },
  genderStat: {
    fontSize: "16px",
    marginBottom: "4px",
    marginTop: "4px"
  }
};

export default Dashboard;
