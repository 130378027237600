import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBrand } from "../graphql-use/queries";
import { generateClient } from "aws-amplify/api";
import { updateBrand } from "../graphql-use/mutations";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

const BrandPage = () => {
  const { brandId } = useParams();
  const [brand, setBrand] = useState(null);

  const [name, setName] = useState("");
  const [status, setStatus] = useState("");

  const client = generateClient();

  useEffect(() => {
    const fetchBrand = async () => {
      try {
        const result = await client.graphql({
          query: getBrand,
          variables: { id: brandId }
        });
        const fetchedBrand = result.data.getBrand;

        // Set initial values for editable fields
        setName(fetchedBrand.name);
        setStatus(fetchedBrand.status);

        // Set the entire brand for display purposes
        setBrand(fetchedBrand);
      } catch (error) {
        console.error("Error fetching brand", error);
      }
    };

    fetchBrand();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId]);

  if (!brand) {
    return <div>Loading...</div>;
  }

  // Handler for saving changes
  const handleSave = async () => {
    try {
      // Send API call to update brand
      // Adjust the mutation based on your GraphQL schema
      const result = await client.graphql({
        query: updateBrand,
        variables: {
          input: {
            id: brandId,
            name,
            status
          }
        }
      });

      console.log("Brand updated:", result.data.updateBrand);

      // Optionally, update the brand state with the saved values
      setBrand(result.data.updateBrand);
      NotificationManager.success(
        "Brand information saved successfully",
        "Success"
      );
    } catch (error) {
      console.error("Error updating brand", error);
      NotificationManager.error("Error updating brand", "Error");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "600px", margin: "auto" }}>
      <h2 style={{ marginBottom: "20px", borderBottom: "1px solid #ddd" }}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "1.5em",
            fontWeight: "bold",
            border: "none",
            outline: "none"
          }}
        />
      </h2>
      <p>
        <strong>ID:</strong> {brand.id}
      </p>
      <p>
        <strong>Status:</strong>{" "}
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          style={{
            padding: "8px",
            fontSize: "1em",
            width: "100%",
            marginBottom: "20px"
          }}
        >
          <option value="APPROVED">APPROVED</option>
          <option value="PENDING">PENDING</option>
          <option value="REJECTED">REJECTED</option>
          <option value="DISABLED">DISABLED</option>
        </select>
      </p>
      <button
        onClick={handleSave}
        style={{
          padding: "10px",
          fontSize: "1em",
          backgroundColor: "#ff7d69",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer"
        }}
      >
        Save
      </button>

      <NotificationContainer />
    </div>
  );
};

export default BrandPage;
