import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import {
  listCollections,
  listCollectionProducts
} from "../graphql-use/queries";
import {
  deleteCollectionProducts,
  deleteCollection
} from "../graphql-use/mutations";

const Collections = () => {
  const [collections, setCollections] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  const client = generateClient();

  useEffect(() => {
    fetchCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCollections = async () => {
    try {
      const result = await client.graphql({
        query: listCollections,
        variables: {
          limit: 2000
        }
      });
      setCollections(result.data.listCollections.items);
    } catch (error) {
      console.error("Error fetching collections", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddCollection = () => {
    const nextOrder = collections.length > 0 ? collections.length + 1 : 1;
    navigate("/add-collection", { state: { nextOrder: nextOrder } });
  };

  const handleDeleteCollection = async (collectionId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this collection along with its products?"
    );
    if (!confirmed) return;

    try {
      // Step 1: Fetch all products associated with this collection
      const productsResult = await client.graphql({
        query: listCollectionProducts, // Query to get products by collectionId
        variables: {
          filter: { collectionId: { eq: collectionId } },
          limit: 2000
        }
      });

      const productsToDelete = productsResult.data.listCollectionProducts.items;

      // Step 2: Loop through each product and delete it
      for (let product of productsToDelete) {
        await client.graphql({
          query: deleteCollectionProducts, // Mutation to delete each product
          variables: {
            input: { id: product.id }
          }
        });
      }

      // Step 3: Delete the collection itself
      await client.graphql({
        query: deleteCollection,
        variables: {
          input: { id: collectionId }
        }
      });

      // Step 4: Refresh the collections after deletion
      setCollections((prevCollections) =>
        prevCollections.filter((collection) => collection.id !== collectionId)
      );
    } catch (error) {
      console.error("Error deleting collection and its products", error);
    }
  };

  const searchedCollections = collections.filter((collection) =>
    collection.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedCollections = searchedCollections.sort(
    (a, b) => a.order - b.order
  );

  return (
    <div style={styles.container}>
      <div>
        <div style={styles.header}>
          <div>
            <label style={styles.searchLabel}>
              Search:
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearch}
                style={styles.searchInput}
              />
            </label>
          </div>
          <button onClick={handleAddCollection} style={styles.addButton}>
            Add Collection
          </button>
        </div>

        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.tableHeader}>Title</th>
              <th style={styles.tableHeader}>ID</th>
              <th style={styles.tableHeader}>Hidden</th>
              <th style={styles.tableHeader}>Order</th>
              <th style={styles.tableHeader}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedCollections.map((collection) => (
              <tr key={collection.id} style={styles.tableRow}>
                <td style={styles.tableCell}>
                  <Link
                    to={`/collections/${collection.id}`}
                    style={styles.link}
                  >
                    {collection.title}
                  </Link>
                </td>
                <td style={styles.tableCell}>
                  <Link
                    to={`/collections/${collection.id}`}
                    style={styles.link}
                  >
                    {collection.id}
                  </Link>
                </td>
                <td style={styles.tableCell}>
                  <Link
                    to={`/collections/${collection.id}`}
                    style={styles.link}
                  >
                    {collection.hidden.toString()}
                  </Link>
                </td>
                <td style={styles.tableCell}>
                  <Link
                    to={`/collections/${collection.id}`}
                    style={styles.link}
                  >
                    {collection.order}
                  </Link>
                </td>
                <td style={styles.tableCell}>
                  <button
                    onClick={() => handleDeleteCollection(collection.id)}
                    style={styles.deleteButton}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  container: {
    margin: "20px"
  },
  header: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  searchLabel: {
    marginRight: "16px"
  },
  searchInput: {
    padding: "8px",
    marginRight: "8px"
  },
  addButton: {
    padding: "8px",
    marginLeft: "16px"
  },
  table: {
    width: "100%",
    borderCollapse: "collapse"
  },
  tableHeader: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f2f2f2"
  },
  tableRow: {
    cursor: "pointer"
  },
  tableCell: {
    border: "1px solid #ddd",
    padding: "8px"
  },
  link: {
    textDecoration: "none",
    color: "inherit"
  },
  deleteButton: {
    padding: "5px 10px",
    cursor: "pointer",
    backgroundColor: "#f44336",
    color: "white",
    border: "none",
    borderRadius: "4px"
  }
};

export default Collections;
